<template>
  <div>
    <!-- eslint-disable -->
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="saveData">
        <b-card class="">
          <b-row>
            <b-col cols="12" md="10" class="pr-0">
              <b-form-group label="" label-for="Nombre">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="templateData.name"
                >
                  <b-form-input
                    placeholder="Nombre de la plantilla"
                    v-model="templateData.name"
                    id="Nombre"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo nombre es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="2">
              <b-row>
                <b-col cols="12" class="d-flex justify-content-center">
                  <b-button
                    variant="warning"
                    class=""
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="repeateAgain"
                  >
                    Añadir
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <b-card
          v-for="(item, index) in templateData.template_detail"
          :id="index"
          :key="index"
        >
          <b-row>
            <b-col cols="12" md="10">
              <b-form-group
                :label="item.number + '. Titulo'"
                label-size="sm"
                label-for="Titulo"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="item.description"
                >
                  <b-form-textarea
                    :value="item.description"
                    v-model="item.description"
                    id="Titulo"
                    placeholder="Titulo"
                    rows="3"
                    no-resize
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo nombre es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="2" class="pt-md-1 pb-1">
              <b-row>
                <b-col cols="12" class="d-flex justify-content-center">
                  <b-button
                    variant="danger"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    class=""
                    @click="removeItem(index)"
                  >
                    - Eliminar
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="d-flex justify-content-center">
                  <b-button
                    variant="success"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    class="mt-1"
                    @click="repeateAgain2(index)"
                  >
                    + Agregar
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="12">
              <b-row>
                <b-col cols="12" md="12" class="mb-1">
                  <b-table
                    :hover="true"
                    :items="item.children"
                    responsive
                    :fields="fields"
                    show-empty
                    stacked="md"
                    empty-text="No existen"
                    :sticky-header="false"
                  >
                    <!-- Column: USER -->
                    <template #cell(index)="data">
                      <b-media vertical-align="center">
                        {{ data.item.number }}
                      </b-media>
                    </template>
                    <!-- Column: USER -->
                    <template #cell(description)="data">
                      <b-media vertical-align="center">
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="data.item.description"
                        >
                          <b-form-textarea
                            v-model="data.item.description"
                            id="Descripcion"
                            placeholder="Descripcion"
                            rows="3"
                            no-resize
                          />
                          <small v-if="errors.length > 0" class="text-danger"
                            >El campo nombre es requerido</small
                          >
                        </validation-provider>
                      </b-media>
                    </template>
                    <!-- Column: USER -->
                    <template #cell(ref_legal)="data">
                      <b-media vertical-align="center">
                        <b-form-textarea
                          v-model="data.item.ref_legal"
                          placeholder="Ref. legal"
                          id="ref_legal"
                          rows="3"
                        />
                      </b-media>
                    </template>
                    <!-- Column: USER -->
                    <template #cell(finding)="data">
                      <b-media vertical-align="center">
                        <b-form-textarea
                          placeholder="Hallazgo o evidencia"
                          v-model="data.item.finding"
                          id="finding"
                          rows="3"
                        />
                      </b-media>
                    </template>
                    <!-- Column: Actions -->
                    <template #cell(action)="data">
                      <b-button
                        @click="removeItemSub(data.index, index)"
                        size="md"
                        class=""
                        variant="flat-danger"
                      >
                        <feather-icon variant="" size="20" icon="Trash2Icon" />
                      </b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>

        <b-row>
          <b-col cols="12">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
              :disabled="isDisabled"
            >
              <b-spinner v-if="isDisabled" small />
              <span v-if="isDisabled" class="px-1">guardando...</span>
              <span v-else>Guardar cambios</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BLink,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import TemplateService from "@/services/TemplateService";
export default {
  data() {
    return {
      templateData: {
        name: "",
        template_detail: [
          {
            id: 1,
            parent_id: null,
            plantilla_id: 1,
            description: "",
            number: "1",
            ref_legal: "",
            finding: "",
            children: [
              {
                id: 1,
                description: "",
                ref_legal: "",
                finding: "",
                parent_id: 1,
                plantilla_id: 1,
                number: "1.1",
                finding: "",
              },
            ],
          },
        ],
      },
      fields: [
        {
          key: "index",
          label: "Nro",
          thStyle: { width: "2%" },
        },
        {
          key: "description",
          label: "Descripcion",
          thStyle: { width: "53%" },
        },
        {
          key: "ref_legal",
          label: "Ref. Legal",
          thStyle: { width: "20%" },
        },
        {
          key: "finding",
          label: "Hallazgo o Ev.",
          thStyle: { width: "20%" },
        },
        {
          key: "action",
          label: "Acciones",
          thStyle: { width: "5%" },
        },
      ],
      nextTodoId: 1,
      nextTodoId2: 1,
      isDisabled: false,
      required,
    };
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BTable,
    BMedia,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
  },
  mounted() {
    // this.initTrHeight();
    this.getData();
  },
  // created() {
  //   window.addEventListener("resize", this.initTrHeight);
  // },
  // destroyed() {
  //   window.removeEventListener("resize", this.initTrHeight);
  // },
  methods: {
    async getData() {
      const id = this.$route.params.id;
      const resp = await TemplateService.getTemplate(id, this.$store);
      if (resp) {
        this.templateData = resp.data;
      }
    },
    repeateAgain() {
      this.templateData.template_detail.push({
        parent_id: null,
        description: "",
        number: "",
        ref_legal: "",
        finding: "",
        children: [],
      });
      console.log(
        "this.templateData.template_detail",
        this.templateData.template_detail
      );
      // this.$nextTick(() => {
      //   this.trAddHeight(this.$refs.row[0].offsetHeight);
      // });
      this.recalcularPadre();
    },
    repeateAgain2(id) {
      this.templateData.template_detail[id].children.push({
        plantilla_id: this.templateData.template_detail[id].children.length + 1,
        description: "",
        number: "",
        ref_legal: "",
        finding: "",
        finding: "",
      });
      this.recalcularHijos(id);
      console.log(
        "this.templateData.template_detail[id].children",
        this.templateData.template_detail[id].children
      );
    },
    async saveData() {
      const isValid = await this.$refs.simpleRules.validate();
      if (isValid) {
        this.isDisabled = true;
        var template_detail = [];
        for (let i = 0; i < this.templateData.template_detail.length; i++) {
          const element = this.templateData.template_detail[i];
          var templateUnique = {
            number: element.number,
            description: element.description,
            ref_legal: element.ref_legal,
            finding: element.finding,
            children: {
              create: element.children,
            },
          };
          template_detail.push(templateUnique);
        }
        const templateData = {
          name: this.templateData.name,
          template_detail: template_detail,
        };
        console.log("template_detail", template_detail);
        const resp = await TemplateService.updateTemplate(
          this.$route.params.id,
          templateData,
          this.$store
        );
        if (resp.status || resp.success) {          
          this.$swal({
            title: "Añadido",
            text: "Plantilla actualizada",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.$router.push({ name: "template-list" });
        } else {
          this.isDisabled = false;
          this.$swal({
            title: "Error!",
            text: " Hubo un error al guardar la plantilla",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      }
      //armar el json:

      // send template_detail as data
    },
    removeItem(index) {
      this.templateData.template_detail.splice(index, 1);
      // this.trTrimHeight(this.$refs.row[0].offsetHeight);
      this.recalcularPadre();
    },
    removeItemSub(indexChild, indexParent) {
      console.log(
        "indexParent",
        indexParent,
        this.templateData.template_detail[indexParent].children
      );
      console.log("indexChild", indexChild);
      this.templateData.template_detail[indexParent].children.splice(
        indexChild,
        1
      );
      this.recalcularHijos(indexParent);
    },
    recalcularHijos(indexParent) {
      //recalcula los numeros de un padre
      for (
        let i = 0;
        i < this.templateData.template_detail[indexParent].children.length;
        i++
      ) {
        this.templateData.template_detail[indexParent].children[i].number =
          this.templateData.template_detail[indexParent].number +
          "." +
          (i + 1).toString();
      }
    },
    recalcularPadre() {
      for (let i = 0; i < this.templateData.template_detail.length; i++) {
        this.templateData.template_detail[i].number = (i + 1).toString();
        this.recalcularHijos(i);
      }
    },
    // initTrHeight() {
    //   this.trSetHeight(null);
    //   this.$nextTick(() => {
    //     this.trSetHeight(this.$refs.form.scrollHeight);
    //   });
    // },
  },
};
</script>